.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

main {
    display: flex;
    flex: 1;
    background-color: floralwhite;
}

.content {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 30px; /* Adjust if needed to avoid overlap with header */
    margin-left: 190px; /* Adjust if needed to avoid overlap with sidebar */
    width: 100%;
    height: 100%;
    --s: 30px; /* control the size */

    --c: #0000, #282828 0.5deg 119.5deg, #0000 120deg;
    --g1: conic-gradient(from 60deg at 56.25% calc(425% / 6), var(--c));
    --g2: conic-gradient(from 180deg at 43.75% calc(425% / 6), var(--c));
    --g3: conic-gradient(from -60deg at 50% calc(175% / 12), var(--c));
    background: var(--g1), var(--g1) var(--s) calc(1.73 * var(--s)), var(--g2), var(--g2) var(--s) calc(1.73 * var(--s)), var(--g3) var(--s) 0, var(--g3) 0 calc(1.73 * var(--s)) #1e1e1e;
    background-size: calc(2 * var(--s)) calc(3.46 * var(--s));
    background-color: rgba(0,0,0, 1);
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:20%;
    margin-left:10%;
    flex-direction:column;
}

.book-wrapper {
    width: 150px;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.book {
    width: 100%;
    height: auto;
    filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.137));
}

.book-wrapper .book-page {
    width: 50%;
    height: auto;
    position: absolute;
    animation: paging 0.5s linear infinite;
    transform-origin: left;
}

@keyframes paging {
    0% {
        transform: rotateY(0deg) skewY(0deg);
    }

    50% {
        transform: rotateY(90deg) skewY(-20deg);
    }

    100% {
        transform: rotateY(180deg) skewY(0deg);
    }
}

.loaderText {
    color:goldenrod;
    font-weight:200px;
    font-size:20px;
}
