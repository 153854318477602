/* PDFViewer.css */

.pdf-viewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
    margin-top: 5%;
}

.pdf-buttons-container {
    margin-top: 20px;
}

.button-common {
    margin: 0 5px;
    cursor: pointer;
    font-weight: 700;
    font-family: Helvetica,"sans-serif";
    transition: all .2s;
    padding: 10px 20px;
    border-radius: 100px;
    background: #d0f0c0;
    border: 1px solid transparent;
    border-color: none;
    font-size: 15px;
}

    .button-common:hover {
        background: #0c2d17;
        stroke:yellow;

    }

    .button-common > svg {
        width: 30px;
        height: 30px;
        transition: transform .3s ease-in-out;
    }
    .button-common:active {
        transform: scale(0.95);
    }

.previous-button:hover svg {
    transform: translateX(-5px);
}

.next-button:hover svg {
    transform: translateX(5px);
}


.pdf-page-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.pdf-page-wrapper {
    position: relative;
    margin: 0 10px;
}

.pdf-page {
    width: 100%;
    height: auto;
}

.overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.overlay {
    position: absolute;
    border: 1px solid;
}

.bleed {

    border-color: red;
}

.trim {
    border-color: blue;
}

.margin {
    border-color: green;
}

.safe-area {
    border-color: yellow;
}

