.sidebar {
    width: 250px; /* Adjust the width as needed */
    background-color: #d0f0c0; /* Optional: Add background color for clarity */
    padding: 40px; /* Optional: Add padding for better appearance */
    box-shadow: 20px 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
    position: fixed; /* Stick the sidebar to the left side */
    top: 60px; /* Adjust to the height of the header */
    left: 0;
    height: calc(100vh - 60px); /* Ensure it covers the full height of the viewport minus header */
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 500; /* Ensure it is below the header */
}

    .sidebar h2 {
        margin-top: 5px;
        margin-bottom: 0;
    }

    .sidebar input {
        margin-bottom: 20px;
    }

.device-selector {
    margin-top: 20px;
}

.Documents-btn {
    display: flex;
    align-items: center;
    justify-content: safe center;
    width: fit-content;
    height: 45px;
    border: none;
    padding: 0px 15px;
    border-radius: 5px;
    background-color: #d0f0c0;
    gap: 10px;
    cursor: pointer;
    transition: all 0.3s;
    margin-bottom: 20px;
}

.folderContainer {
    width: 40px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
}

.fileBack {
    z-index: 1;
    width: 80%;
    height: auto;
}

.filePage {
    width: 50%;
    height: auto;
    position: absolute;
    z-index: 2;
    transition: all 0.3s ease-out;
}

.fileFront {
    width: 85%;
    height: auto;
    position: absolute;
    z-index: 3;
    opacity: 0.95;
    transform-origin: bottom;
    transition: all 0.3s ease-out;
}

.text {
    color: goldenrod;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.Documents-btn:hover .filePage {
    transform: translateY(-5px);
}

.Documents-btn:hover {
    background-color: #0c2d17;
}

.Documents-btn:active {
    transform: scale(0.95);
}

.Documents-btn:hover .fileFront {
    transform: rotateX(30deg);
}

.sidebar select {
    -webkit-appearance: none; /* Removes default styling for Webkit browsers */
    -moz-appearance: none; /* Removes default styling for Mozilla browsers */
    appearance: none; /* Removes default styling */
    padding: 5px 5px;
    border: 0px;
    border-radius: 0px; /* Rounded corners */
    background-color: #d0f0c0; /* Gold background */
    color: goldenrod; /* Text color */
    font-weight: bold;
    cursor: pointer; /* Cursor changes to pointer on hover */
    position: relative;
    background-image: url('data:image/svg+xml;utf8,<svg fill="goldenrod" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: right 0px center; /* Position for the dropdown arrow */
    background-size: 25px; /* Size of the dropdown arrow */
    padding: 5px 15px;
    transition: all 0.3s;
    height: 40px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    width: 64%;
    margin-bottom: 20px;
}

    .sidebar select:hover {
        -webkit-appearance: none; /* Removes default styling for Webkit browsers */
        -moz-appearance: none; /* Removes default styling for Mozilla browsers */
        appearance: none; /* Removes default styling */
        padding: 5px 5px;
        border-radius: 5px; /* Rounded corners */
        background-color: #0c2d17; /* Gold background */
        color: goldenrod; /* Text color */
        font-weight: bold;
        cursor: pointer; /* Cursor changes to pointer on hover */
        position: relative;
        background-image: url('data:image/svg+xml;utf8,<svg fill="goldenrod" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom dropdown arrow */
        background-repeat: no-repeat;
        background-position: right 0px center; /* Position for the dropdown arrow */
        background-size: 25px; /* Size of the dropdown arrow */
        padding: 5px 15px;
        transition: all 0.3s;
        height: 45px;
    }

/* Style for options inside the select */
.sidebar option {
    background: #0c2d17; /* Background of options */
    color: goldenrod; /* Text color of options */
    display:flex;
    align-items:center;
}

    .sidebar option:hover {
        background: #d0f0c0; /* Background of options */
        color: goldenrod; /* Text color of options */
    }


.form {
    --input-focus: #2d8cf0;
    --font-color: goldenrod;
    --font-color-sub: #666;
    --bg-color: #d0f0c0;
    --main-color: #323232;
    padding: 20px;
    background: #0c2d17;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    margin-bottom: 50px;
}

.title {
    color: var(--font-color);
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 0px;
    align-self:center;
}

    .title span {
        color: var(--font-color-sub);
        font-weight: 600;
        font-size: 17px;
    }

.input {
    width: 200px;
    height: 40px;
    border-radius: 5px;
    border: 2px solid var(--main-color);
    background-color: var(--bg-color);
    box-shadow: 4px 4px var(--main-color);
    font-size: 15px;
    font-weight: 600;
    color: var(--font-color);
    padding: 5px 10px;
    outline: none;
    margin-top:10px;
    align-content:center;
    align-items:center;
}

    .input::placeholder {
        color: var(--font-color-sub);
        opacity: 0.8;
    }

    .input:focus {
        border: 2px solid var(--input-focus);
    }

.button {
    --main-focus: #2d8cf0;
    --font-color: goldenrod;
    --bg-color-sub: #0c2d17;
    --bg-color: #d0f0c0;
    --main-color: #0c2d17;
    position: relative;
    width: 150px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    background-color: var(--bg-color);
    border-radius: 10px;
    overflow: hidden;
}

.button, .button__icon, .button__text {
    transition: all 0.3s;
}

    .button .button__text {
        transform: translateX(21px);
        color: var(--font-color);
        font-weight: 600;
    }

    .button .button__icon {
        position: absolute;
        transform: translateX(109px);
        height: 100%;
        width: 39px;
        background-color: var(--bg-color-sub);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .button .svg {
        width: 18px;
        fill: goldenrod;
        margin-right:5px;
    }

    .button:hover {
        background: var(--bg-color);
    }

        .button:hover .button__text {
            color: transparent;
        }

        .button:hover .button__icon {
            width: 148px;
            transform: translateX(0);
        }

    .button:active {
        transform: translate(3px, 3px);
        box-shadow: 0px 0px var(--main-color);
    }


