header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px; /* Adjust height as needed */
    background-color: #0c2d17; /* Adjust background color as needed */
    color: white;
    display: flex;
    align-items: center;
    padding: 0 20px; /* Adjust padding as needed */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
    z-index: 1000; /* Ensure it is above the sidebar */
}

@font-face {
    font-family: 'Book';
    src: url('Sketch Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sketch';
    src: url('Arcade Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

header h1 {
    margin: 0;
    font-family: Book;
    font-size: 50px;
    font-weight:50;
}

header h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-family:Sketch;
    font-size: 35px;
    font-weight: 10;
}

img {
    height:150%;
}


